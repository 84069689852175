<template>
  <IAmOverlay
    :loading="isEmpty(tableItem)"
    :spinner-variant="'info'"
  >
    <b-card
      class="mb-2"
      body-class="p-1 d-flex-center"
    >
      <p
        v-if="tableItem[0]"
        class="d-flex-center font-weight-bolder mb-0 flex-wrap"
      >
        <span class="mr-75 font-medium-2">{{ `${resolveAirlineFlightNumber(tableItem[0].airline, tableItem[0].flightNumber)}` }}</span>
        <span class="mr-75 font-medium-2">{{ convertISODateTime(tableItem[0].departureDate).dayMonth }}</span>
        <span class="mr-75 font-medium-2">{{ `${tableItem[0].departure}${tableItem[0].arrival}` }}</span>
        <span class="mr-75 font-medium-2">{{ convertISODateTime(tableItem[0].departureDate, tableItem[0].departureTimezone).hourMin }} {{ convertISODateTime(tableItem[0].arrivalDate, tableItem[0].arrivalTimezone).hourMin }}</span>
        <span class="mr-75 font-medium-2">{{ `| ${tableItem[0].airCraft ? tableItem[0].airCraft : ''}` }}</span>
        <span
          v-if="tableItem[0].operating && tableItem[0].operating === 'BL'"
          class="mr-75 font-medium-2 text-danger"
        >
          PACIFIC AIRLINES
        </span>
      </p>
    </b-card>

    <b-card
      class="mb-0"
      no-body
    >
      <BAlert
        show
        variant="warning"
        class="px-1 py-25 text-center fw-700 mb-25"
      >
        Giá chỉ mang tính chất tham khảo, chưa bao gồm thuế, phí!
      </BAlert>

      <BTableLite
        v-if="!isEmpty(tableItem)"
        bordered
        responsive
        small
        hover
        striped
        body-class="p-0"
        class="mb-1 rounded"
        thead-class="text-dark font-weight-bolder text-nowrap"
        tbody-class="text-dark text-nowrap"
        :fields="['classBooking', 'availability', 'priceTemp']"
        :items="tableItem[0].bookingClassAvail"
      >
        <template
          v-for="(column, indexColumn) in ['classBooking', 'availability', 'priceTemp']"
          v-slot:[`head(${column})`]="data"
        >
          <div :key="indexColumn">
            <span v-if="data.column !== 'priceTemp'">{{ $t(`reservation.${data.column}`) }}</span>
            <span v-else>{{ $t(`reservation.${data.column}`) }} (NGƯỜI LỚN)</span>
          </div>
        </template>
        <template #cell(classBooking)="{item}">
          <div
            :class="`text-center font-medium-3 font-weight-bolder
          ${tableItem[0].airline === 'VU' ? item.refundable ? '' : 'text-danger' : ''}`"
          >
            {{ item.code }}
          </div>
        </template>
        <template #cell(availability)="{item}">
          <div
            class="text-center font-weight-bolder"
            :class="item.availability === 0 ? 'text-danger' : (item.availability > 0 && item.availability < 9) ? 'text-warning' : 'text-success'"
          >
            {{ item.availability }}
          </div>
        </template>
        <template #cell(priceTemp)="data">
          <p
            v-if="!isEmpty(data.item.prices)"
            :id="`pax_priceTemp_${data.index}`"
            class="mb-0"
          >
            <span class="fw-700">{{ data.item.prices[0].fareBasisCode }}: </span>
            <span
              v-if="tableItem[0].airline && !['VU'].includes(tableItem[0].airline)"
              class="font-weight-bolder"
            > Giá: {{ formatCurrency(data.item.prices[0].net) }}</span>
            <span
              v-if="tableItem[0].airline && !['VU'].includes(tableItem[0].airline)"
              class="font-weight-bolder"
            > - Thuế, phí: {{ formatCurrency(data.item.prices[0].tax) }}</span>
            <span class="text-warning font-weight-bolder"> - Tổng: {{ formatCurrency(data.item.prices[0].fare) }}</span>
          </p>

          <b-tooltip
            :id="`tooltip-pax_priceTemp_${data.index}`"
            :target="`pax_priceTemp_${data.index}`"
            triggers="hover"
            variant="info"
            placement="top"
            boundary="window"
          >
            <div
              :class="`font-italic text-white ${isMobileView ? 'font-small-1' : 'font-small-3'}`"
            >
              (Chưa bao gồm phí dịch vụ)
            </div>
          </b-tooltip>

          <!-- <p
                v-for="(price, i) in item.prices"
                :key="i"
                class="mb-75 border-bottom"
              >
                {{ $t(`reservation.${price.paxType}`) }}({{ price.fareBasisCode }}): Giá: <span class="font-weight-bolder">{{ formatCurrency(price.net) }}</span> - Thuế, phí: <span class="font-weight-bolder">{{ formatCurrency(price.tax) }}</span> - Tổng: <span class="text-warning font-weight-bolder">{{ formatCurrency(price.fare) }}</span>
              </p> -->
        </template>
      </BTableLite>
    </b-card>
  </IAmOverlay>
</template>

<script>
import {
  BTableLite,
  BCard,
  BTooltip,
  BAlert,
} from 'bootstrap-vue'
import {
  computed, toRefs,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import IAmOverlay from '@/components/IAmOverlay.vue'

import {
  formatCurrency,
  convertShortTrip,
  formatDateMonth,
  convertISODateTime, resolveAirlineFlightNumber,
} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BTableLite,
    BTooltip,
    BAlert,

    IAmOverlay,
  },
  props: {
    dataTrip: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { dataTrip } = toRefs(props)
    const tableItem = computed(() => dataTrip.value.map(item => item.trips.map(t => t.segments.map(seg => seg))).flat(3))

    return {
      tableItem,
      isEmpty,
      formatCurrency,
      convertShortTrip,
      formatDateMonth,
      convertISODateTime,
      resolveAirlineFlightNumber,
    }
  },
}
</script>

<style lang="">

</style>
