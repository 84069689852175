var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.isEmpty(_vm.tableItem),
      "spinner-variant": 'info'
    }
  }, [_c('b-card', {
    staticClass: "mb-2",
    attrs: {
      "body-class": "p-1 d-flex-center"
    }
  }, [_vm.tableItem[0] ? _c('p', {
    staticClass: "d-flex-center font-weight-bolder mb-0 flex-wrap"
  }, [_c('span', {
    staticClass: "mr-75 font-medium-2"
  }, [_vm._v(_vm._s("".concat(_vm.resolveAirlineFlightNumber(_vm.tableItem[0].airline, _vm.tableItem[0].flightNumber))))]), _c('span', {
    staticClass: "mr-75 font-medium-2"
  }, [_vm._v(_vm._s(_vm.convertISODateTime(_vm.tableItem[0].departureDate).dayMonth))]), _c('span', {
    staticClass: "mr-75 font-medium-2"
  }, [_vm._v(_vm._s("".concat(_vm.tableItem[0].departure).concat(_vm.tableItem[0].arrival)))]), _c('span', {
    staticClass: "mr-75 font-medium-2"
  }, [_vm._v(_vm._s(_vm.convertISODateTime(_vm.tableItem[0].departureDate, _vm.tableItem[0].departureTimezone).hourMin) + " " + _vm._s(_vm.convertISODateTime(_vm.tableItem[0].arrivalDate, _vm.tableItem[0].arrivalTimezone).hourMin))]), _c('span', {
    staticClass: "mr-75 font-medium-2"
  }, [_vm._v(_vm._s("| ".concat(_vm.tableItem[0].airCraft ? _vm.tableItem[0].airCraft : '')))]), _vm.tableItem[0].operating && _vm.tableItem[0].operating === 'BL' ? _c('span', {
    staticClass: "mr-75 font-medium-2 text-danger"
  }, [_vm._v(" PACIFIC AIRLINES ")]) : _vm._e()]) : _vm._e()]), _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('BAlert', {
    staticClass: "px-1 py-25 text-center fw-700 mb-25",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Giá chỉ mang tính chất tham khảo, chưa bao gồm thuế, phí! ")]), !_vm.isEmpty(_vm.tableItem) ? _c('BTableLite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "hover": "",
      "striped": "",
      "body-class": "p-0",
      "thead-class": "text-dark font-weight-bolder text-nowrap",
      "tbody-class": "text-dark text-nowrap",
      "fields": ['classBooking', 'availability', 'priceTemp'],
      "items": _vm.tableItem[0].bookingClassAvail
    },
    scopedSlots: _vm._u([_vm._l(['classBooking', 'availability', 'priceTemp'], function (column, indexColumn) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: indexColumn
          }, [data.column !== 'priceTemp' ? _c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.column))))]) : _c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.column))) + " (NGƯỜI LỚN)")])])];
        }
      };
    }), {
      key: "cell(classBooking)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          class: "text-center font-medium-3 font-weight-bolder\n        ".concat(_vm.tableItem[0].airline === 'VU' ? item.refundable ? '' : 'text-danger' : '')
        }, [_vm._v(" " + _vm._s(item.code) + " ")])];
      }
    }, {
      key: "cell(availability)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-center font-weight-bolder",
          class: item.availability === 0 ? 'text-danger' : item.availability > 0 && item.availability < 9 ? 'text-warning' : 'text-success'
        }, [_vm._v(" " + _vm._s(item.availability) + " ")])];
      }
    }, {
      key: "cell(priceTemp)",
      fn: function fn(data) {
        return [!_vm.isEmpty(data.item.prices) ? _c('p', {
          staticClass: "mb-0",
          attrs: {
            "id": "pax_priceTemp_".concat(data.index)
          }
        }, [_c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(data.item.prices[0].fareBasisCode) + ": ")]), _vm.tableItem[0].airline && !['VU'].includes(_vm.tableItem[0].airline) ? _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" Giá: " + _vm._s(_vm.formatCurrency(data.item.prices[0].net)))]) : _vm._e(), _vm.tableItem[0].airline && !['VU'].includes(_vm.tableItem[0].airline) ? _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" - Thuế, phí: " + _vm._s(_vm.formatCurrency(data.item.prices[0].tax)))]) : _vm._e(), _c('span', {
          staticClass: "text-warning font-weight-bolder"
        }, [_vm._v(" - Tổng: " + _vm._s(_vm.formatCurrency(data.item.prices[0].fare)))])]) : _vm._e(), _c('b-tooltip', {
          attrs: {
            "id": "tooltip-pax_priceTemp_".concat(data.index),
            "target": "pax_priceTemp_".concat(data.index),
            "triggers": "hover",
            "variant": "info",
            "placement": "top",
            "boundary": "window"
          }
        }, [_c('div', {
          class: "font-italic text-white ".concat(_vm.isMobileView ? 'font-small-1' : 'font-small-3')
        }, [_vm._v(" (Chưa bao gồm phí dịch vụ) ")])])];
      }
    }], null, true)
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }